import { Box, BoxProps } from "@mui/material";
import { mergeSx } from "~/helpers";

export const Illustration = ({ sx, ...props }: BoxProps) => (
  <Box
    sx={mergeSx(
      {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      sx
    )}
    {...props}
  />
);
